import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import pl from "timeago.js/lib/lang/pl";

interface Props {
  date: number
  className?: string
}

timeago.register('pl', pl);

export function TimeDisplay({date, className}: Props) {
  return <TimeAgo datetime={date} className={className} locale={"pl"}/>
}